
import de from 'react-intl/locale-data/de';
import fr from 'react-intl/locale-data/fr';
import it from 'react-intl/locale-data/it';
import en from 'react-intl/locale-data/en';

import messages_de from './translations/messages_de.json';
import messages_fr from './translations/messages_fr.json';
import messages_it from './translations/messages_it.json';
import messages_en from './translations/messages_en.json';

const config = {
  logo: {
    localized: false,
    extension: 'png'
  },
  availableLocales: [
    {id: 'de', label: 'Deutsch', messages: messages_de, localeData: de },
    {id: 'fr', label: 'Français', messages: messages_fr, localeData: fr },
    {id: 'it', label: 'Italiano', messages: messages_it, localeData: it },
    {id: 'en', label: 'English', messages: messages_en, localeData: en }
  ],
  defaultLanguage: 'de',
  servers: {
    signaling: 'https://ms-comm.z4butrwhlxv8.nooon.io:443',
    ice: [
      { urls: "stun:ms-comm.ca7cthywqan.nooon.io:443" },
      { urls: "turns:ms-comm.ca7cthywqan.nooon.io:443?transport=udp", 'username': 'nooon', 'credential': '7eQioJtCw9if' },
      { urls: "turns:ms-comm.ca7cthywqan.nooon.io:443?transport=tcp", 'username': 'nooon', 'credential': '7eQioJtCw9if' }
    ]
  },
  callMetadata: {},
  mailsender: 'Nextcare Pro <noreply@nextcare.pro>',
  appName: 'Nextcare Pro'
};

export default config;